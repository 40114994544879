@import "bootswatch/dist/zephyr/variables";
@import "bootstrap/scss/bootstrap";
@import "bootswatch/dist/zephyr/bootswatch";

#gridColorPickerLabel:hover {
  cursor: pointer;
}

#flathex svg {
  transform: rotate(30deg);
}